import React from "react";
import "../App.css";
import Logo from "../img/logo/logo_header.webp";

const Header = () => {
  return (
    <div className="header">
      <picture>
        <source media="(max-width: 1200px)" srcSet={Logo} loading="lazy" />
        <img className="header-logo" src={Logo} alt="会社のロゴ" loading="lazy" width="150" height="52" />
      </picture>
    </div>
  );
};

export default Header;