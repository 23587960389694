import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import Chapter from "./Chapter";
import TopImageRectangle400 from "../img/logo/logo_top_rectangle_400x400.webp";
import TopImageRectangle600 from "../img/logo/logo_top_rectangle_600x400.webp";
import TopImageRectangle1200 from "../img/logo/logo_top_rectangle_1200x400.webp";
import TopImageRectangle2400 from "../img/logo/logo_top_rectangle_2400x400.webp";
import TopImageRectangle3600 from "../img/logo/logo_top_rectangle_3600x400.webp";
import Data from "../data/contents.json";
import "../App.css";


const displayContents = () => {
  return (
    Data.data.chapters.map((data, index) => {
      return <Chapter key={index} {...data} />;
    })
  )
}

function Home() {
  return (
    <div className="main">
      <Header />
      <picture>
        <source media="(max-width: 400px)" srcSet={TopImageRectangle400} loading="lazy" />
        <source media="(max-width: 600px)" srcSet={TopImageRectangle600} loading="lazy" />
        <source media="(max-width: 1200px)" srcSet={TopImageRectangle1200} loading="lazy" />
        <source media="(max-width: 2400px)" srcSet={TopImageRectangle2400} loading="lazy" />
        <source media="(max-width: 3600px)" srcSet={TopImageRectangle3600} loading="lazy" />
        <img className="top-image-rectangle" src={TopImageRectangle3600} alt="トップ画像" loading="lazy" />
      </picture>
      {displayContents()}
      <Footer />
    </div>
  );
}
export default Home;