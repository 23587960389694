import React from "react";
import "../App.css";

const Footer = () => {
    return (
      <div className="footer">
        <p>© Otonakensaku G.K.</p>
      </div>
    );
};

export default Footer;