import React from "react";
import "../App.css";

const simpleCahpter = (props) => {
  return (
    <div className="chapter">
      <h1 className="chapter-name">{props.chapter_name}</h1>
      <h2 className="chapter-title">{props.title}</h2>
      <p className="chapter-description">{props.description}</p>
    </div>
  );
};

const contentChapter = (content, index) => {
  return (
    <div key={index}>
      <h2 className="chapter-content-title">{content.title}</h2>
      <p className="chapter-description">{content.description}</p>
    </div>
  )
};

const miltiContentChapter = (props) => {
  return (
    <div className="chapter">
      <h1 className="chapter-name">{props.chapter_name}</h1>
      {
        props.contents.map((content, index) => {
          return contentChapter(content, index)
        })
      }
    </div>
  );
};

const newsChapter = (props) => {
  const renderNews = (news, index) => {
    return (
      <div key={index} className="chapter-news">
        <h2 className="chapter-news-date">{news.date}</h2>
        <div className="chapter-news-content">
          <h2 className="chapter-news-title">{news.title}</h2>
          <p className="chapter-news-description">
            {news.description}
            <a href={news.url} target="_blank" rel="noopener noreferrer">詳細はこちら</a>
          </p>
        </div>
      </div>
    )
  };
  return (
    <div className="chapter">
      <h1 className="chapter-name">{props.chapter_name}</h1>
      {
        props.contents.map((news, index) => {
          return renderNews(news, index)
        })
      }
    </div>
  );
}

const awardChapter = (props) => {
  const renderAward = (award, index) => {
    let img = require(`../img/award/${award.image}`);
    console.log(img)
    return (
      <div key={index} className="chapter-award">
        <img className="chapter-award-image" src={img} alt={award.title} loading="lazy" width="120" height="120" />
        <div className="chapter-award-content">
          <h2 className="chapter-award-title">{award.title}</h2>
          <p className="chapter-award-description">
            {award.description}
            <a href={award.url} target="_blank" rel="noopener noreferrer">詳細はこちら</a>
          </p>
        </div>
      </div>
    )
  };
  return (
    <div className="chapter">
      <h1 className="chapter-name">{props.chapter_name}</h1>
      <div className="chapter-award-list">
        {
          props.contents.map((award, index) => {
            return renderAward(award, index)
          })
        }
      </div>
    </div>
  );
};

const listingChapter = (props) => {
  return (
    <div className="chapter">
      <h1 className="chapter-name">{props.chapter_name}</h1>
      <p className="chapter-description">{props.company_info.name}</p>
      <p className="chapter-description">{props.company_info.address}</p>
      <p className="chapter-description">{props.company_info.establish}</p>
      <p className="chapter-description">{props.company_info.represent}</p>
      <p className="chapter-description">{props.company_info.capital}</p>
      <p className="chapter-description">{props.company_info.content}</p>
    </div>
  );
};

const formChapter = (props) => {
  return (
    <div className="chapter">
      <h1 className="chapter-name">{props.chapter_name}</h1>
      <p className="chapter-description">
        こちらの<a href={props.url} target="_blank" rel="noopener noreferrer">フォーム</a>よりお願い致します。
      </p>
    </div>
  );
}

const memberChapter = (props) => {
  const renderMember = (member, index) => {
      let img = require(`../img/member/${member.image}`);
      console.log(img)
      return (
        <div className="chapter-member" key={index}>
          <img className="chapter-member-image" src={img} alt={member.name_ja} loading="lazy" width="120" height="170" />
          <p className="chapter-member-text name-ja">{member.name_ja}</p>
          <p className="chapter-member-text name-en">{member.name_en}</p>
          <p className="chapter-member-text position">{member.position}</p>
        </div>
      )
  }

  return (
    <div className="chapter">
      <h1 className="chapter-name">{props.chapter_name}</h1>
      <div className="chapter-member-list">
        {
          props.member_info.map((member, index) => {
            return renderMember(member, index)
          })
        }
      </div>
    </div>
  );
};

const Chapter = (props) => {
  if (props.chapter_type === "simple") {
    return simpleCahpter(props)
  } else if (props.chapter_type === "multi_content") {
    return miltiContentChapter(props)
  } else if (props.chapter_type === "award") {
    return awardChapter(props)
  } else if (props.chapter_type === "news") {
    return newsChapter(props)
  } else if (props.chapter_type === "listing") {
    return listingChapter(props)
  } else if (props.chapter_type === "form") {
    return formChapter(props)
  } else if (props.chapter_type === "member") {
    return memberChapter(props)
  }
};

export default Chapter;